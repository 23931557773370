<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>点检记录导出</span>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
          <div class="form-title">导出信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="时间段" prop="finishTime">
                <el-date-picker
                  v-model="form.finishTime"
                  size="small"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  @change="changeMonth">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="导出内容" prop="exportCon">
                <div>
                  <el-checkbox-group v-model="form.exportCon">
                    <el-row :gutter="20">
                      <el-col :span="6"><el-checkbox label="taskCode">点检代号</el-checkbox></el-col>
                      <el-col :span="6"><el-checkbox label="deviceName">设备名称</el-checkbox></el-col>
                      <el-col :span="6"><el-checkbox label="patrolName">巡视点</el-checkbox></el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6"><el-checkbox label="submitter">提交人</el-checkbox></el-col>
                      <el-col :span="6"><el-checkbox label="members">组员</el-checkbox></el-col>
                      <el-col :span="6"><el-checkbox label="facilityName">位置</el-checkbox></el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="6"><el-checkbox label="deviceStatus">设备状态</el-checkbox></el-col>
                      <el-col :span="6"><el-checkbox label="taskStatus">任务状态</el-checkbox></el-col>
                      <el-col :span="6"><el-checkbox label="completeTime">完成时间</el-checkbox></el-col>
                    </el-row>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" @click="onSubmit" size="small">导出</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
      class="export-dialog">
      <div>
        正在导出中，稍后请在
        <span>【<el-button type="text" @click="toMessage">消息中心</el-button>】</span>
        <span><i class="el-icon-arrow-right"></i></span>
        <span>【<el-button type="text" @click="toMessage">待下载列表</el-button>】</span>
        模块中查看
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        finishTime:[],
        exportCon: ['taskCode','deviceName','patrolName','submitter'],
      },
      rules: {
        finishTime: [{ required: true, message: "必填项", trigger: "change" }],
        exportCon: [{ required: true, message: "必填项", trigger: "change" }],
      },
      dialogVisible: false,
      startDate: null,
      endDate: null,
    }
  },
  methods: {
    // 获取最后一天
    getLastDay(lastDate) {
      let y = new Date(lastDate).getFullYear();
      let m = new Date(lastDate).getMonth()+1;
      let d = new Date(y,m,0).getDate();
      m = m < 10 ? '0' + m : m;
      d = d < 10 ? '0' + d : d;
      return [y,m,d].join("-")
    },
    // 选择日期
    changeMonth(value) {
      let endLastDate = this.getLastDay(value[1])
      let sDate = new Date(new Date(value[0]).toLocaleDateString())
      let eDate = new Date(new Date(endLastDate).toLocaleDateString())
      this.startDate = sDate.getTime()
      this.endDate = eDate.getTime()
    },
    toMessage() {
      this.$router.push({ name: 'stayDownloadList' })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = {
              taskType: 1,
              startDate: this.startDate,
              endDate: this.endDate,
              fields: this.form.exportCon,
          }
          
          this.loading = true;
          this.$ajax.post("recordExport", form).then((res) => {
            // const blob = new Blob([res])
            // const fileName = '记录报表.xls';
            // const linkNode = document.createElement('a');
            // linkNode.download = fileName;
            // linkNode.style.display = 'none';
            // linkNode.href = URL.createObjectURL(blob);
            // document.body.appendChild(linkNode);
            // linkNode.click();
            // URL.revokeObjectURL(linkNode.href);
            // document.body.removeChild(linkNode);
            if(res.code==0) {
              this.loading = false;
              this.dialogVisible = true
            }else {
              this.loading = false;
              this.dialogVisible = false
              this.$message.error('导出失败')
            }
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() { 
    
  },
  destroyed() {

  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
/deep/.export-dialog .el-dialog {
  margin-top: 10vh !important;
  background: #f0f9eb;
  border-radius: 5px;
  box-shadow: 0px 0px 0px;
}
/deep/.export-dialog .el-dialog__header {
  padding: 20px 20px 0px;
}
/deep/.export-dialog .el-dialog__title {
  font-size: 16px;
  color: #67c23a;
}
/deep/.export-dialog .el-dialog__body {
  padding: 10px 20px;
  color: #67c23a;
}
</style>